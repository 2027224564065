.insurance-preview-modal.modal-dialog {
    max-width: 900px !important;
}
.insurance-preview-modal .modal-content {
    background-color: white;
    color: #525f6f;
}
.insurance-preview-modal .modal-content .modal-body {
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.insurance-preview-modal .modal-content .modal-body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    /* display: none; */
}
.insurance-preview-modal .modal-content .modal-body::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
    /* display: none; */
}

.insurance-preview-modal .modal-content .toggle-section-heading i {
    float: right;
    margin-top: 0.25rem;
}
.insurance-preview-modal .modal-content .toggle-section-heading {
    display: block;
    color: #525f6f;
    /* color: white; */
    background-color: #D6D6D6;
    /* background-color: #565d60; */
    border-radius: 10px;
    padding: 0.5rem 1rem;
    font-size: 1em;
    margin-bottom: 1rem;
}
.insurance-preview-modal .modal-content .toggle-section-heading.active {
    /* background-color: #1A1C1E; */
    background-color: #303e67;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    color: #ffffff;
}

.insurance-preview-modal .modal-content label {
    color: #525f6f;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 80%;
    font-weight: 600;
    margin-bottom: 0.25rem;
}
.insurance-preview-modal .modal-content p {
    margin-bottom: 0.25rem;

}
.insurance-preview-modal.modal-dialog row {
    margin-bottom: 15px !important;
}

.insurance-preview-modal .modal-content .modal-header {
    justify-content: center;
    /* background-color: #303e67;
    width: 100% !important;
    border-radius: 5px;
    margin-bottom: 5px; */
}

.insurance-preview-modal .modal-content .modal-header .modal-title {
    font-weight: 400;
    font-size: 1.75em;
    text-transform: uppercase;
    /* color: white; */
}
.insurance-preview-modal-primary-btn {
    background-color: #303e67;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
}
.insurance-preview-modal-secondary-btn {
    background-color: white;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: black;
    cursor: pointer;
}
.insurance-preview-modal-danger-btn {
    background-color: red;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
    border: #ffffff;
}
.insurance-preview-modal-success-btn {
    background-color: #28a745;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
    border: #ffffff;
}
.btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
}
.insurance-primary-btn {
    background-color: #303e67;
    width: 50% !important;
    color: #ffffff;
    height: 150px !important;
    font-size: large;
    border-radius: 10px;
    font-weight: 400;
    text-transform: uppercase;
    opacity: 0.7;
    border: none;
}
#insurance-preview-modal-popover {
    width: 100%;
    margin-bottom: 10px;
    border: solid 1px #303e67;
}
#insurance-preview-modal-popover-header {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}
#insurance-preview-modal-popover-body {
    font-size: 13px;
}
.insurance-preview-modal .insurance-preview-modal-nav {
    background-color: #ffffff;
}
.insurance-preview-modal .insurance-preview-modal-nav-item {
    border: solid 0px #303e67;
    background-color: #D6D6D6;
    color: #303e67;
}

.insurance-preview-modal .insurance-preview-modal-nav-item .active {
    background-color: #303e67;
    border: solid 1px #303e67;
}